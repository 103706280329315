<template>
	<w-layout column justify-center align-center fill-height>
		<w-flex shrink :mb-3="!readonly">
			<w-layout row justify-center align-center @click="onLogoUploadAsked">
				<v-img
					v-if="logo_file_path"
					:class="readonly ? 'app-logo-icon' : 'pointer app-logo-icon app-logo-icon-uploader'"
					contain
					:width="size"
					:height="size"
					:src="logo_file_path"
				/>
				<v-icon v-else-if="!readonly" :class="readonly ? 'app-logo-icon' : 'pointer app-logo-icon app-logo-icon-uploader'" x-large>add_photo_alternate</v-icon>
			</w-layout>
		</w-flex>
		<w-flex v-if="!readonly" shrink>
			<w-layout row justify-center align-center>
				<v-btn color="primary" class="text-none" :loading="isSelectingLogo" @click="onLogoUploadAsked">
					<v-icon left> add_photo_alternate </v-icon>
					{{ $t('mobileapp.actions.select-logo') }}
				</v-btn>
			</w-layout>
		</w-flex>
		<w-flex v-if="!readonly">
			<v-btn color="primary" class="text-none" href="https://www.appicon.co/" target="_blank" >
				<v-icon left> palette </v-icon>
					{{  $t('mobileapp.actions.use_icon_generator') }}
			</v-btn>
		</w-flex>
	</w-layout>
</template>

<script>
export default {
	name: 'MobileAppIcon',
	props: {
		value: {
			required: true,
			type: Object
		},
		size: {
			required: false,
			type: Number,
			default: 160
		},
		readonly: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			isSelectingLogo: false,
			acceptedMimeTypes: '.jpg,.jpeg,.png,.svg'
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		logo: {
			get: function () {
				return this.application.logo
			},
			set: function (val) {
				this.application.logo = val
			}
		},
		logo_file_path: {
			get: function () {
				return this.application.logo_file_path
			},
			set: function (val) {
				this.application.logo_file_path = val
			}
		}
	},
	methods: {
		onLogoUploadAsked: function () {
			if (!this.readonly) {
				this.isSelectingLogo = true
				window.addEventListener(
					'focus',
					() => {
						this.isSelectingLogo = false
					},
					{ once: true }
				)
				const fileInput = document.createElement('input')
				if (this.acceptedMimeTypes) {
					fileInput.accept = this.acceptedMimeTypes
				}
				fileInput.multiple = false
				fileInput.type = 'file'
				fileInput.addEventListener(
					'input',
					function (e) {
						e.preventDefault()
						this.logo = e.target.files[0]
						this.application.logo_file_path = URL.createObjectURL(e.target.files[0])
						this.isSelectingLogo = false
						fileInput.remove()
					}.bind(this)
				)
				fileInput.click()
			}
		}
	}
}
</script>

<style scoped>
.app-logo-icon {
	border-radius: 20px !important;
}
.app-logo-icon-uploader {
	border: 3px dashed;
}
.app-logo-icon-uploader .v-image__image {
	border-radius: 20px !important;
}
</style>
